.img__container {
  display: flex;
  flex-direction: column;
  align-items: center;
  margin: 0px var(--margin-medium)
}

.img__container img {
  height: 55%;
}

.label {
  height: 100px;
  font-size: var(--text-large);
  text-align: center;
  overflow-y: hidden;
}

.buttonContainer {
  margin-top: 100px;
}

@media screen and (max-width:1096px) {
  .label {
    font-size: var(--text-md-one);
    height: auto;
    margin: 0;
    padding: 0;
  }

  .img__container {
    height: auto;
    margin-bottom: var(--margin-small)
  }

  .img__container img {
    height: 60px;
  }

  .buttonContainer {
    margin: 0;
  }

  .img__container img {
    display: none;
  }

}