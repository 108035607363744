.col {
    display: flex;
    flex-direction: column;
    align-items: center;
    max-width: 50vw;
}



.col p,
.col span {
    width: 80%;
    font-size: var(--text-md-one);
    margin: var(--margin-micro);
}

.col .back {
    width: 80%;
    margin-top: var(--margin-medium);
    font-size: var(--text-md-one);
    padding: var(--padding-small) var(--padding-small);
    border-radius: 8px;
    background-color: #fff;
    color: #000;
    font-weight: bold;
    border: none
}

.back:hover {
    opacity: .6;
    cursor: pointer
}

.indent {
    padding: var(--padding-tiny) 0 var(--padding-tiny) var(--padding-medium);
}

@media screen and (max-width: 1096px) {
    .col {
        max-width: none;
    }

    .col p,
    .col span {
        font-size: var(--text-md-two);
    }

    .col .back {
        border-radius: 8px;
        font-size: var(--text-md-three);
    }

}