.col,
.col__mobile {
  display: flex;
  flex-direction: column;
}

.col {
  width: 55vw;
}

.col__mobile {
  display: none;
}

.flex {
  display: flex;
  flex-wrap: wrap;
}

@media screen and (max-width: 1200px) {
  .col {
    display: none;
  }

  .col__mobile {
    display: flex;
    max-width: none;
    width: 90vw;
  }

  .flex {
    width: 80%;
    margin-left: 10%;
  }



}