.col {
  display: flex;
  flex-direction: column;
  align-items: center;
  max-width: 50vw;
  justify-content: space-around;
}

.infobox {
  display: flex;
  width: 100%;
  padding: 8px;
  overflow-y: hidden;
  justify-content: center;
  margin-top: 40px;
}

.infobox img {
  height: 20px;
  min-width: 20px;
}

.infobox p {
  transform: translate(10px, 12px);
  font-size: var(--text-md-one);
  width: 70%;
}

@media screen and (max-width: 1096px) {
  .col {
    max-width: none;
  }

  .infobox {
    width: 90vw;
    margin-top: 0px;
    height: 150px
  }


  .infobox p {
    font-size: var(--text-small);
    transform: translate(-10px, 15px);
    z-index: 10;
  }

  .infobox img {
    height: 15px;
    transform: translateX(-6px);
  }

}