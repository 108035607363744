.root {
    display: flex;
    flex-direction: column;
    align-items: center;
    width: 80vw;
    padding-left: 10vw;
    padding-right: 10vw;
    padding-top: var(--padding-large);
    justify-content: center;
    background-color: #E1E3E5;
}

.root h3,
.root p,
.root a {
    color: black;
}

.root h3 {
    margin-bottom: var(--margin-large)
}

.section {
    display: flex;
    flex-direction: column;
    align-items: center;

    margin-bottom: var(--margin-medium)
}

.section p {
    text-align: center;
}

p.bold {
    font-weight: bold;
    margin-bottom: var(--margin-tiny)
}

.bold a {
    /* color: rgb(0, 125, 179); */
}

p.bold:last-child {
    margin-bottom: var(--margin-medium)
}

@media screen and (max-width: 1096px) {
    .root {
        width: 80vw;
    }

    .root h3 {
        text-align: center;
    }

}