.orderRoot {
    display: flex;
    flex-direction: column;
    box-sizing: border-box;
    color: black;
    width: 100%;
    padding: var(--padding-medium)
}

.sectionTop,
.sectionBottom {
    display: flex;
    flex: 2;
}

.button__mobile,
.mobile__disabled {
    display: none;
}

.sectionTop {
    flex-direction: column;
    flex: 1.5;
}

.sectionBottom {
    padding: 32px 0px;
    /* border: 4px solid rgb(0, 60, 113); */
    background: #E1E3E5;
}

.header {
    background-color: rgb(0, 60, 113);
    color: white;
    padding: var(--padding-small) var(--padding-large);
    margin-bottom: var(--margin-large);
}

.header h2 {
    font-size: var(--text-large);
}

.row {
    display: flex;
    margin-bottom: var(--margin-md-one);
    justify-content: space-between;
}

.row img {
    width: 200px;
    height: 128px;
    margin-right: var(--margin-small);

}

.row h6 {
    font-size: var(--text-md-one);
    color: #007DB3
}

.row p {
    font-size: var(--text-md-one);
    color: #6A737B
}

.row .textbox {
    display: flex;
    flex-direction: column;
    width: 80%;
}

.row input {
    width: 35px;
    height: 35px;
    margin-right: var(--margin-md-one);

}



.half,
.contactHalf {
    display: flex;
    flex-direction: column;
    flex: 1;
}

.half:nth-child(2) {
    align-items: center;
    justify-content: center;
}

.half:first-child {
    border-right: 4px solid grey;
}



.form h3 {
    color: rgb(0, 60, 113);
    font-size: var(--text-md-one);
    font-weight: bold;
    height: 60px;
}

.half p {
    color: black;
    font-size: var(--text-md-one);
    line-height: 30px;
}

.form {
    display: flex;
    flex-direction: column;
    padding: 8px 32px;
    height: 100%;
}

.form .input {
    border: 0px;
    box-sizing: border-box;
    padding: 12px 16px;
    font-size: var(--text-md-one);
    width: 100%;
    margin-bottom: var(--margin-tiny);
    color: black;
}

.input::placeholder {
    color: black;
    font-size: var(--text-md-one);
}

.checkboxContainer {
    display: flex;
    align-items: center;
    margin: var(--margin-small) 0px;
}

.checkboxContainer p,
.checkboxContainer a {
    color: #6A737B;
    font-family: Arial, Helvetica, sans-serif;
    flex: 15;
    font-size: var(--text-md-one);
    line-height: normal;

}

.checkboxContainer input {
    flex: 1;
    margin-right: var(--margin-small);
    min-height: 25px;
    align-self: flex-start;
}


.button,
.disabled,
.button__mobile,
.mobile__disabled {
    position: relative;
    right: 0;
    font-size: var(--text-large);
    margin-top: var(--margin-medium);
    color: #6A737B;
    border: 4px solid rgb(0, 60, 113);
    background: transparent;
    width: 50%;
    padding: var(--padding-tiny) 0
}

.button:hover {
    opacity: .6;
    cursor: pointer;
}

.disabled {
    opacity: .3;
}

.contactContent {
    margin-top: var(--margin-md-one);
    display: flex;
    flex-direction: column;
    align-items: center;
}

.contactContent p,
.contactContent a {
    color: rgb(0, 60, 113);
    font-size: var(--text-large);
    height: 40px;
    text-align: center;
    margin-bottom: var(--margin-tiny);
}



.contactContent img {
    width: 25%;
    margin: var(--margin-small) 0px;
}

.info {
    margin-bottom: 15%;
}

@media screen and (max-width: 1096px) {

    .sectionBottom,
    .sectionTop {
        flex-direction: column;
    }

    .button,
    .disabled {
        display: none;
    }

    .button__mobile,
    .mobile__disabled {
        display: block;
        font-size: var(--margin-medium)
    }


    .sectionTop h2,
    .sectionBottom h3 {
        font-size: var(--text-md-one);
    }

    .row input,
    .checkboxContainer .checkboxInput {
        max-width: 20px;
        margin-right: var(--margin-md-one);

    }

    .checkboxContainer p,
    .checkboxContainer a {
        font-size: var(--text-md-two)
    }

    .textbox h6,
    .textbox p,
    .form .input,
    .form .input::placeholder {
        border: 0px;
        color: #6A737B;
        font-size: var(--text-md-two);
        font-family: Arial, Helvetica, sans-serif;
        margin-left: 12px;
    }

    .row img {
        height: 100px;
        width: 80px;
    }

    .form {
        height: auto;
    }




    .half:first-child {
        border-right: 0px;
    }

    .form h3 {
        height: auto;
        font-size: var(--text-md-one);
        margin-bottom: var(--margin-md-one)
    }

    .contactContent img {
        max-height: 30px;
        padding: 0;
        margin: 0;
    }



    .info {
        align-items: center;
    }

    .contactContent .contact {
        margin: 0;
        padding: 0;
        font-size: var(--text-md-one);
    }



    .contactContent p {
        padding: 0;
        margin: 0;
        height: auto;
    }


    .half p {
        /* border: 2px solid orange; */
        line-height: normal;
    }

    .info p,
    .info a {
        color: #6A737B;
        font-size: var(--text-md-three);
        padding: 0;
        margin: 0;
    }

    .row input {
        margin: 0;
        margin-left: 15px;
    }




}