.root {
  margin: 0px var(--margin-medium);
}

.normal {
  fill: white;
  stroke: #053f73;
  cursor: pointer
}

.selected {
  fill: rgb(212, 59, 59);
  stroke: #053f73;
  cursor: pointer
}

svg {
  stroke-width: 3px;
}

.sides {
  display: flex;
  justify-content: center;
}


.sides p {
  font-size: var(--text-md-one);
  margin: 0px var(--margin-large);
}


@media screen and (max-width: 1096px) {
  .root svg {
    width: 100%;
    margin: 0px;
    height: 300px;
  }

  .sides {
    justify-content: center;
  }

  .sides p {
    font-size: var(--text-small);
    margin: 10px 50px;
  }


}