.col {
    display: flex;
    flex-direction: column;
    align-items: center;


}

.result {
    width: 80vw;
    text-align: justify;
    font-size: var(--text-md-one);
    max-width: 800px;
}

.back {
    margin-top: var(--margin-medium);
    width: 80vw;
    font-size: var(--text-md-one);
    padding: 16px 0;
    border-radius: 20px;
    background-color: #fff;
    color: #000;
    font-weight: bold;
    max-width: 400px;
}

.back:hover {
    opacity: .6;
    cursor: pointer
}

.asterisk {
    max-width: 60vw;
    margin-top: var(--margin-large);
    font-size: var(--text-small);
    text-align: justify;

}