.root,
.selected {
    display: flex;
    align-items: center;
    justify-content: center;
    flex: 1;
    border-radius: 56px;
    background-color: #fff;
    border: none;
    margin: var(--margin-medium);
    padding: var(--padding-medium);
    cursor: pointer;
    overflow-y: hidden;
    width: 480px;
    box-sizing: border-box;
}

.root:hover,
.selected:hover {
    background-color: rgb(0, 125, 179);
    /* color: #fff; */
}

.selected {
    background-color: rgb(0, 125, 179);
}

.flex p,
.flex {
    display: flex;
    color: #000;
    font-size: var(--text-md-one);
    width: 90%;
    align-items: center;
    justify-content: center;
}

.selected .flex p {
    color: #fff;
    font-weight: bold;
}

.flex img {
    margin-left: var(--margin-tiny);
    width: 44px;
}

@media screen and (max-width: 1200px) {
    .root,
    .selected {
        width: 500px;
    }
}

@media screen and (max-width: 576px) {
    .root,
    .selected {
        width: 320px;
        margin: 15px 0px;
    }

    .flex p,
    .flex {
        font-size: var(--text-md-two);
    }
}
