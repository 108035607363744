.col {
    display: flex;
    flex-direction: column;
    align-items: center;
}

.smiley__container {
    display: flex;
    justify-content: center;
    flex-wrap: wrap;
    gap: 0.5rem;
}

.smiley__description {
    flex-direction: column;
    align-items: center;
    max-width: 40vw;
}

.smiley__description h6 {
    font-size: var(--text-huge);
    font-weight: bold;
    text-align: center;
    margin-bottom: 12px;
}

.smiley__description p {
    font-size: var(--text-large);
    text-align: center;
    line-height: 42px;
}
