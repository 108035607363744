* {
    padding: 0;
    margin: 0;
    font-family: Arial, Helvetica, sans-serif;

    --text-giant: 48px;
    --text-huge: 36px;
    --text-large: 32px;
    --text-medium: 24px;
    --text-md-one: 20px;
    --text-md-two: 16px;
    --text-md-three: 14px;
    --text-small: 12px;
    --text-tiny: 10px;

    --margin-large: 48px;
    --margin-large-one: 32px;
    --margin-medium: 24px;
    --margin-md-one: 20px;
    --margin-small: 16px;
    --margin-tiny: 8px;
    --margin-micro: 4px;

    --padding-large: 48px;
    --padding-medium: 24px;
    --padding-small: 16px;
    --padding-tiny: 8px;
    --padding-micro: 4px;
}
:root {
    overflow-x: hidden;
}

input[type="checkbox"] {
    cursor: pointer;
}

input:focus {
    outline: none;
}

input:active {
    outline: none;
}
